<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('users_page.title')"
      :sub-title="$t('users_page.sub_title')"
    />
    <section class="wameed-dashboard-page-content_body">
      <b-row class="no-gutters px-0">
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="employeesDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <users-page-icon />
            </b-avatar>
            <h4 class="h-panel__title text-med-20 my-4">
              {{ $t('users_page.employees') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="employeesSeekersDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <companies-page-icon />
            </b-avatar>
            <h4 class="h-panel__title text-med-20 my-4">
              {{ $t('users_page.talent_seekers') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="newAccountsDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <new-accounts-page-icon />
            </b-avatar>
            <h4 class="h-panel__title text-med-20 my-4">
              {{ $t('users_page.activate_accounts') }}
            </h4>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!-- <page-header
      :title="$t('packages.title')"
      :subTitle="'23 ' + $t('packages.subtitle')"
      :btnTitle="$t('packages.add_new_packages')"
      routeName="addPackage"
    /> -->
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    PageHeader,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    employeesDetails() {
      this.$router.push({
        name: 'users-employees',
        params: { lang: this.$i18n.locale },
      });
    },
    employeesSeekersDetails() {
      this.$router.push({
        name: 'users-business',
        params: { lang: this.$i18n.locale },
      });
    },
    newAccountsDetails() {
      this.$router.push({
        name: 'users-new-accounts',
        params: { lang: this.$i18n.locale },
      });
    },
  },
};
</script>

<template>
  <div class="section-heading px-3 d-md-flex d-block">
    <div>
      <h1 class="section-heading__title text-bold-24">{{ title }}</h1>
      <p v-if="subTitle" class="section-heading__sub-title text-bold-16 mb-0">{{ subTitle }}</p>
    </div>
    <router-link v-if="routeName" :to="{ name: routeName }" :class="classes">
      <span :class="'mx-0 '">{{ btnTitle }}</span>
      <!-- <add-circle-icon class="mx-2" /> -->
    </router-link>
    <wameed-btn
      :classes="'rounded-12 ' + classes"
      v-if="btn"
      :title="btnTitle"
      type="button"
      :variant="variant"
      :prependIcon="btnPrependIcon"
      @submitAction="btnAction"
      :disabled="disabledBtn"
    />
  </div>
</template>

<script>
import WameedBtn from './WameedBtn.vue';
export default {
  components: { WameedBtn },
  props: {
    title: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: null,
    },

    subTitle: {
      type: String,
      default: null,
    },
    routeName: {
      type: String,
      default: null,
    },
    btnTitle: {
      type: String,
      default: null,
    },
    btnPrependIcon: {
      type: String,
      default: null,
    },
    btn: {
      type: Boolean,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    disabledBtn: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({}),
  methods: {
    btnAction() {
      this.$emit('btnAction');
    },
  },
};
</script>